<template>
	<div>
		<a-modal title="编辑账号" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<a-spin :spinning="false">
				<div class="flex alcenter mt24">
					<div class="ft14 ftw500 cl-info">门店开通数量：</div>
					<div>
						 <a-input-number style="width:200px;" v-model="form.store_num" />
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					store_num:'',
				}
			}
		},
		methods: {
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('platform/admin/changeStoreNum',{
					shop_id:record.shop_id,
					open_num:this.form.store_num
				}).then(res=>{
					this.$message.success('添加成功',1,()=>{
						this.confirmLoading=false;
						this.$emit('ok');
					})
					
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
		}
	}
</script>

<style>

</style>
